import * as React from "react";
import { Link } from "gatsby";
import Layout from "../components/layout";
import OOPS_Img from "../images/404_image.jpg";
import { navigate } from "@reach/router"

// styles
const pageStyles = {
  color: "#232129",
  padding: "96px",
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
  backgroundImage: `url(${OOPS_Img})`,
  backgroundSize: `cover`,
  backgroundPosition: `center`,
  position:`relative`
}
const headingStyles = {
  marginTop: 0,
  marginBottom: 64,
  maxWidth: 320,
}

const paragraphStyles = {
  marginBottom: 48,
}
const codeStyles = {
  color: "#8A6534",
  padding: 4,
  backgroundColor: "#FFF4DB",
  fontSize: "1.25rem",
  borderRadius: 4,
}

const goBackPreviousPage = ()=>{
  let previousPage = sessionStorage.getItem('previousFormPage')
  let targetPage = "/"
  if(previousPage){
    targetPage = previousPage
  }
  navigate(targetPage)
}

// markup
const NotFoundPage = () => {


  return (
    <div className="thank-you-page">
      <>
        <Layout GQLPage="" Layout="Without_Banner" classNames={"search-header person-header news_details_wrapper details-header"} popular_search_slug="insights">
          <main style={pageStyles} className="page-content-found">
            <div className="overlay_bg"></div>

            <div className="page-content-inner">
              {/* <div className="title">Ooops!!...</div> */}
              <div className="sub-title">Thank you</div>
              <p className="para-content">A member of our team will be in contact soon.</p>
              <div className="btn-not-found">
                <button className="btn" onClick={goBackPreviousPage}>Back to Previous Page</button>
                <button className="btn" onClick={goBackPreviousPage}>Close</button>
                <Link className="btn" to="/contact-us">Contact Us</Link>
              </div>
            </div>
          </main>
        </Layout>
      </>
    </div>
  )
}

export default NotFoundPage
